import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faGripDotsVertical, faX } from "@fortawesome/pro-solid-svg-icons";
import { StoredDataset } from "@services/space/datasets/models";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { Button } from "@cpchem/covalence-ui";
import {
    FormatDate,
    formatTemperatures,
    getSampleName,
    getSubmittedOnDate,
    getTemperatures
} from "../../../../utilities/dataset-utils/dataset-utils";

export interface SelectedDatasetProps {
    className?: string;
    dataset: StoredDataset;
    useOverlayLayout: boolean;
    datasetFileName: string;
    handleDismissDataset: (fileName: string) => void;
    handleDragStart: (
        event: React.DragEvent<HTMLLIElement>,
        fileName: string
    ) => void;
    handleDragOver: (event: React.DragEvent<HTMLLIElement>) => void;
    handleDragEnd: () => void;
    handleDatasetNameClick?: (dataset: StoredDataset) => void;
    testId?: string;
}

export function SelectedDataset({
    className,
    dataset,
    useOverlayLayout,
    datasetFileName,
    handleDismissDataset,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDatasetNameClick,
    testId
}: SelectedDatasetProps) {
    const cnParts = ["selected-dataset"];
    const dragButtonCnParts = ["dataset-drag"];
    const RemoveFileExtension = (fileName: string) =>
        fileName.split(".").slice(0, -1).join(".");

    if (className) {
        cnParts.push(className);
        dragButtonCnParts.push(className);
    }

    if (useOverlayLayout) {
        cnParts.push("selected-overlay-dataset");
        dragButtonCnParts.push("overlay-dataset-drag");
    }

    const selectedDatasetTestId = testId
        ? `${testId}-selected-dataset`
        : undefined;
    const selectedDatasetDragButtonTestId = testId
        ? `${testId}-drag`
        : undefined;
    const selectedDatasetDismissButtonTestId = testId
        ? `${testId}-dismiss`
        : undefined;

    const cn = cnParts.join(" ");
    const dragButtonCn = dragButtonCnParts.join(" ");

    function GetDatasetContent(
        dataset: StoredDataset,
        useOverlayLayout: boolean
    ) {
        if (useOverlayLayout) {
            const sampleName = getSampleName(dataset);

            const hasDate = getSubmittedOnDate(dataset) !== null;
            return (
                <div className="overlay-dataset">
                    <div>
                        <Button
                            className="overlay-dataset-sample-name"
                            text={sampleName}
                            icon={
                                !dataset.isValid ? (
                                    <FontAwesomeIcon
                                        data-testid={`${sampleName}-invalid-icon`}
                                        icon={faTriangleExclamation as IconProp}
                                        className={"dataset-warning-icon"}
                                    />
                                ) : undefined
                            }
                            isIconAfterText
                            variant="text"
                            color="primary"
                            size="medium"
                            onClick={() =>
                                handleDatasetNameClick &&
                                handleDatasetNameClick(dataset)
                            }
                            testId={`${sampleName}-dataset-name-button`}
                        />
                    </div>
                    <div>{dataset.fileName}</div>
                    <div>
                        Temperature:{" "}
                        {formatTemperatures(getTemperatures(dataset))}
                    </div>
                    <div>
                        Date Ran:{" "}
                        {hasDate
                            ? FormatDate(getSubmittedOnDate(dataset))
                            : "Not Avaliable"}
                    </div>
                </div>
            );
        }
        return (
            <div className="dataset-name">
                {RemoveFileExtension(datasetFileName)}
            </div>
        );
    }

    return (
        <li
            className={cn}
            data-testid={`${datasetFileName}-${selectedDatasetTestId}`}
            draggable
            onDragStart={(event) => {
                event.stopPropagation();
                handleDragStart(event, datasetFileName);
            }}
            onDragOver={(event) => {
                event.stopPropagation();
                handleDragOver(event);
            }}
            onDragEnd={(event) => {
                event.stopPropagation();
                handleDragEnd();
            }}
        >
            <div className="selected-dataset-icon-and-name">
                <button
                    className={dragButtonCn}
                    data-testid={selectedDatasetDragButtonTestId}
                    aria-label={`Drag ${datasetFileName} to reorder`}
                >
                    <FontAwesomeIcon
                        icon={faGripDotsVertical as IconProp}
                        className="drag-icon"
                    />
                </button>
                {GetDatasetContent(dataset, useOverlayLayout)}
            </div>
            <button
                className="dataset-dismiss"
                onClick={() => handleDismissDataset(datasetFileName)}
                data-testid={`${datasetFileName}-${selectedDatasetDismissButtonTestId}`}
                aria-label={`Remove ${datasetFileName} from selected datasets`}
            >
                <FontAwesomeIcon
                    icon={faX as IconProp}
                    className="dismiss-icon"
                />
            </button>
        </li>
    );
}
