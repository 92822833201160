import { BaseAnalysisRequest, BaseAnalysisResponse } from "../base-models";
import { RheologyViscositySampleMeasurements } from "../cy-rheology/models";

export const VISCOSITY_PLOT = "viscosity_vs_frequency";
export const DELTA_PLOT = "van_gurp_palmen";
export const STORAGE_PLOT = "storage_modulus_vs_frequency";
export const LOSS_PLOT = "loss_modulus_vs_frequency";

export interface RheologyOverlayAnalysisRequest extends BaseAnalysisRequest {
    applyYieldStressTerm: boolean;
    fixedN: number;
    samples: RheologyOverlayViscositySample[];
}

export interface RheologyOverlayViscositySample {
    name: string;
    resinType: string | null;
    fileName: string;
    dateRan: string | null;
    measurements: RheologyViscositySampleMeasurements;
    plotTypes: string[] | null;
}

export interface RheologyOverlayAnalysisResponse extends BaseAnalysisResponse {
    samples: PlotAnalysisSampleInfo[];
}

export interface PlotAnalysisSampleInfo {
    name: string;
    plots: PlotAnalysisSamplePlotInfo;
}

export interface PlotAnalysisSamplePlotInfo {
    [plotType: string]: PlotAnalysisSamplePlotSeriesCollection;
}

export interface PlotAnalysisSamplePlotSeriesCollection {
    summary: PlotAnalysisSamplePlotSummaryInfo;
    series: PlotAnalysisSamplePlotSeriesInfo[];
}

export interface PlotAnalysisSamplePlotSummaryInfo {
    temperatures: number[];
}

export interface PlotAnalysisSamplePlotSeriesInfo {
    name: string | null;
    xValues: number[];
    yValues: number[];
}
