import React, { useState, useCallback, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faGripDotsVertical,
    faChevronDown,
    faChevronUp
} from "@fortawesome/pro-solid-svg-icons";
import {
    StoredDataset,
    StoredDatasetGroup
} from "@services/space/datasets/models";
import { Button } from "@cpchem/covalence-ui";
import { SelectedDataset } from "./selected-dataset";
import "./selected-dataset-group-styles.scss";

export interface SelectedDatasetGroupProps extends StoredDatasetGroup {
    handleDismissDataset: (groupName: string, fileName: string) => void;
    handleDatasetReorder: (
        groupName: string,
        updatedDatasets: StoredDataset[]
    ) => void;
    handleDragStart: (
        event: React.DragEvent<HTMLLIElement>,
        groupName: string
    ) => void;
    handleDragOver: (event: React.DragEvent<HTMLLIElement>) => void;
    handleDragEnd: () => void;
    onGroupNameClick: (groupName: string) => void;
    testId?: string;
}

export function SelectedDatasetGroup({
    name,
    group,
    handleDismissDataset,
    handleDatasetReorder,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    onGroupNameClick,
    testId
}: SelectedDatasetGroupProps) {
    const [isOpen, setIsOpen] = useState(true);
    const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(
        null
    );
    const [localDatasetsOrder, setLocalDatasetsOrder] =
        useState<StoredDataset[]>(group);
    useEffect(() => {
        setLocalDatasetsOrder(group);
    }, [group]);

    const toggleAccordion = useCallback(
        (event: React.MouseEvent | React.KeyboardEvent) => {
            if (!event.defaultPrevented) {
                setIsOpen(!isOpen);
            }
        },
        [isOpen]
    );

    const handleGroupNameClick = useCallback(() => {
        onGroupNameClick(name);
    }, [name, onGroupNameClick]);

    const handleDatasetDismiss = (fileName: string) => {
        handleDismissDataset(fileName, name);
    };

    const handleDatasetDragStart = useCallback(
        (event: React.DragEvent<HTMLElement>, index: number) => {
            event.stopPropagation();
            setDraggedItemIndex(index);
            event.dataTransfer.effectAllowed = "move";
            document.body.classList.add("dragging");
        },
        []
    );

    const handleDatasetDragOver = (
        event: React.DragEvent<HTMLLIElement>,
        index: number
    ) => {
        event.preventDefault();
        if (draggedItemIndex !== null && draggedItemIndex !== index) {
            const updatedDatasets = [...localDatasetsOrder];
            const [draggedItem] = updatedDatasets.splice(draggedItemIndex, 1);
            updatedDatasets.splice(index, 0, draggedItem);
            setLocalDatasetsOrder(updatedDatasets);
            setDraggedItemIndex(index);
        }
    };

    const handleDatasetDragEnd = useCallback(() => {
        handleDatasetReorder(name, localDatasetsOrder);
        setDraggedItemIndex(null);
        document.body.classList.remove("dragging");
    }, [handleDatasetReorder, name, localDatasetsOrder]);

    const AccordionIcon = isOpen ? faChevronUp : faChevronDown;

    return (
        <li
            className={`selected-dataset-group ${isOpen ? "open" : ""}`}
            data-testid={`${testId}`}
            draggable
            onDragStart={(event) => handleDragStart(event, name)}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
        >
            <div
                className="group-header"
                onClick={toggleAccordion}
                role="button"
                tabIndex={0}
                onKeyDown={(event) => {
                    if (event.key === "Enter" || event.key === " ") {
                        toggleAccordion(event);
                    }
                }}
            >
                <div className="group-icon-and-name">
                    <button
                        className="group-drag"
                        data-testid={`${testId}-group-drag`}
                        aria-label={`Drag ${name} to reorder`}
                        onClick={(e) => e.preventDefault()}
                    >
                        <FontAwesomeIcon
                            icon={faGripDotsVertical as IconProp}
                            className="drag-icon"
                        />
                    </button>

                    <Button
                        className="group-name"
                        onClick={handleGroupNameClick}
                        text={name}
                        variant="text"
                        testId={`${testId}-group-name`}
                    />
                </div>
                <FontAwesomeIcon
                    icon={AccordionIcon as IconProp}
                    className="accordion-icon"
                />
            </div>
            {isOpen && (
                <ul className="group-datasets">
                    {localDatasetsOrder.map((dataset, index) => (
                        <SelectedDataset
                            key={dataset.fileName}
                            datasetFileName={dataset.fileName}
                            handleDismissDataset={(fileName) =>
                                handleDatasetDismiss(fileName)
                            }
                            handleDragStart={(event) =>
                                handleDatasetDragStart(event, index)
                            }
                            handleDragOver={(event) =>
                                handleDatasetDragOver(event, index)
                            }
                            handleDragEnd={handleDatasetDragEnd}
                            className={
                                draggedItemIndex === index ? "dragging" : ""
                            }
                            testId={`${testId}-dataset-${index}`}
                            useOverlayLayout={false}
                            dataset={dataset}
                        />
                    ))}
                </ul>
            )}
        </li>
    );
}
