import React, { useEffect, useState } from "react";
import {
    OverlayPlotType,
    RheologyOverlayParameters
} from "../../../../interfaces/analysis-types";
import { PlotsSection } from "./plots-section";
import _ from "lodash";
import { SpaceAccordion } from "../../../../components/space-accordion/space-accordion";
import { DatasetsSection } from "../../../report-parameters/cy-rheology/components/datasets-section";
import { Divider } from "@cpchem/covalence-ui";
import { ValidateDatasets } from "../../../report-parameters/cy-rheology/cy-rheology-validations";
import {
    hasInvalidPlotSelections,
    GetUnavailablePlots,
    setDatasetValidationFlags
} from "../validations";
import { StoredDataset } from "@services/space/datasets/models";

interface RheologyOverlayFormProps {
    initialParameters: RheologyOverlayParameters;
    plotOptions: OverlayPlotType[];
    onUpdate: (updates: Partial<RheologyOverlayParameters>) => void;
    onValidation: (isValid: boolean) => void;
    isLoading?: boolean;
    testId?: string;
}

export function RheologyOverlayForm({
    initialParameters,
    plotOptions,
    onUpdate,
    onValidation,
    isLoading,
    testId
}: RheologyOverlayFormProps): JSX.Element {
    const [openAccordion, setOpenAccordion] = useState<string | null>(
        "Datasets"
    );
    const [isDatasetsValid, setIsDatasetsValid] = useState<boolean>(false);
    const [isSelectedPlotsValid, setIsSelectedPlotsValid] =
        useState<boolean>(true);
    const [disabledPlots, setDisabledPlots] = useState<OverlayPlotType[]>([]);
    const [parameters, setParameters] =
        useState<RheologyOverlayParameters>(initialParameters);

    const handleSelectedPlotsUpdate = (value: OverlayPlotType[]) => {
        handleUpdate({ ["selectedPlotTypes"]: value });
    };

    const handleSelectedDatasetsUpdate = (value: StoredDataset[]) => {
        const disabledPlotNames = disabledPlots.map((plot) => plot.key);
        handleUpdate({
            ["selectedDatasets"]: value,
            ["selectedPlotTypes"]:
                value.length === 0
                    ? []
                    : parameters.selectedPlotTypes.filter(
                          (plot) => !disabledPlotNames.includes(plot.key)
                      )
        });
    };

    const handleUpdate = (updates: Partial<RheologyOverlayParameters>) => {
        setParameters((prevParameters) => {
            const updatedParameters = _.cloneDeep({
                ...prevParameters,
                ...updates
            });
            onUpdate(updatedParameters);
            return updatedParameters;
        });
    };

    useEffect(() => {
        const isValid = ValidateDatasets(parameters.selectedDatasets.length);
        setIsDatasetsValid(isValid);
        onValidation(isValid);

        const unavailablePlots = GetUnavailablePlots(
            parameters.selectedDatasets
        );
        setDisabledPlots(unavailablePlots);
        setDatasetValidationFlags(
            parameters.selectedDatasets,
            parameters.selectedPlotTypes
        );
    }, [
        parameters.selectedDatasets,
        isDatasetsValid,
        onValidation,
        parameters.selectedPlotTypes
    ]);

    useEffect(() => {
        const isValid =
            !hasInvalidPlotSelections(
                parameters.selectedPlotTypes,
                disabledPlots
            ) && parameters.selectedPlotTypes.length > 0;
        setIsSelectedPlotsValid(isValid);
        onValidation(isValid);
    }, [parameters.selectedPlotTypes, disabledPlots, onValidation]);

    useEffect(() => {
        const isValid = isDatasetsValid && isSelectedPlotsValid;
        onValidation(isValid);
    }, [isDatasetsValid, isSelectedPlotsValid, onValidation]);

    return (
        <div className="parameters-content">
            <SpaceAccordion
                label="Datasets"
                isOpen={openAccordion === "Datasets"}
                onToggle={() =>
                    setOpenAccordion(
                        openAccordion === "Datasets" ? "Plots" : "Datasets"
                    )
                }
                defaultOpen
                testId={`${testId}-datasets-accordion`}
            >
                <DatasetsSection
                    selectedDatasets={parameters.selectedDatasets}
                    onUpdateSelectedDatasets={handleSelectedDatasetsUpdate}
                    isLoading={isLoading}
                    isGroupedDatasets={false}
                    testId={`${testId}-datasets-section`}
                    isOverlay
                />
            </SpaceAccordion>
            <Divider />
            <SpaceAccordion
                label="Plots"
                isOpen={openAccordion === "Plots"}
                onToggle={() =>
                    setOpenAccordion(
                        openAccordion === "Plots" ? "Datasets" : "Plots"
                    )
                }
                testId={`${testId}-plots-accordion`}
            >
                <PlotsSection
                    initiallySelectedOptions={parameters.selectedPlotTypes}
                    plotOptions={plotOptions}
                    disabledPlots={disabledPlots}
                    updateSelections={handleSelectedPlotsUpdate}
                    testId={testId}
                />
            </SpaceAccordion>
        </div>
    );
}
