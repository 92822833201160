import React, { useState, useEffect } from "react";
import { Button, Modal, ModalContent } from "@cpchem/covalence-ui";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

import { StoredDataset } from "@services/space/datasets/models";
import { getSampleName } from "../../../utilities/dataset-utils/dataset-utils";
import { MetaDataSection } from "./components/meta-data-section";
import { DataValidationSection } from "./components/data-validation-section";
import { AliasSection } from "./components/alias-section";
import "../styles.scss";
import "./plot-overlay-styles.scss";

interface PlotOverlayModalProps {
    isOpen: boolean;
    dataset: StoredDataset;
    selectedDatasets?: StoredDataset[];
    onRequestClose: () => void;
    onDatasetNameChange?: (newSampleName: string) => void;
    className?: string;
    testId?: string;
}

export function PlotOverlayModal({
    isOpen,
    dataset,
    selectedDatasets = [],
    onRequestClose,
    onDatasetNameChange,
    className,
    testId
}: PlotOverlayModalProps): JSX.Element {
    const [alias, setAlias] = useState("");
    const [aliasError, setAliasError] = useState("");
    const [isRegexValid, setIsRegexValid] = useState(true);

    const cnParts = ["plot-overlay-modal", "space-modal"];

    if (className) {
        cnParts.push(className);
    }

    useEffect(() => {
        if (isOpen) {
            setAlias("");
            setAliasError("");
            setIsRegexValid(true);
        }
    }, [isOpen]);

    function handleAliasChange(value: string, valid: boolean) {
        setAlias(value);
        setIsRegexValid(valid);

        if (!valid) {
            setAliasError("");
            return;
        }

        if (!value.trim()) {
            setAliasError("");
            return;
        }

        const lowerAlias = value.trim().toLowerCase();
        const duplicate = selectedDatasets.some((ds) => {
            if (ds.fileName === dataset.fileName) return false;

            const sampleName = getSampleName(ds).trim().toLowerCase();
            return sampleName === lowerAlias;
        });

        if (duplicate) {
            setAliasError("Another selected dataset already has that name.");
        } else {
            setAliasError("");
        }
    }

    function handleOkClick() {
        if (
            onDatasetNameChange &&
            alias.trim() &&
            !aliasError &&
            isRegexValid
        ) {
            onDatasetNameChange(alias.trim());
        }
        onRequestClose();
    }

    const isOkDisabled = !isRegexValid || !!aliasError;

    const cn = cnParts.join(" ");
    const modalTestId = testId ? `${testId}-plot-overlay-modal` : undefined;
    const metaDataSectionTestId = testId
        ? `${testId}-metadata-section`
        : undefined;
    const dataValidationSectionTestId = testId
        ? `${testId}-validation-section`
        : undefined;
    const aliasSectionTestId = testId ? `${testId}-alias-section` : undefined;
    const okButtonTestId = testId ? `${testId}-ok-button` : undefined;
    const cancelButtonTestId = testId ? `${testId}-cancel-button` : undefined;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            showCloseButton={false}
            className={cn}
            testId={modalTestId}
        >
            <div className="modal-header">
                <h1>Dataset Information</h1>
                <Button
                    className="modal-close-button"
                    variant="text"
                    size="large"
                    icon={<FontAwesomeIcon icon={faTimes as IconProp} />}
                    color="primary"
                    onClick={onRequestClose}
                />
            </div>

            <ModalContent className="plot-overlay-modal-content">
                <MetaDataSection
                    dataset={dataset}
                    testId={metaDataSectionTestId}
                />
                <DataValidationSection
                    dataset={dataset}
                    testId={dataValidationSectionTestId}
                />

                <AliasSection
                    alias={alias}
                    onAliasChange={handleAliasChange}
                    externalError={aliasError}
                    testId={aliasSectionTestId}
                />

                <div className="plot-overlay-modal-footer">
                    <Button
                        text="Cancel"
                        variant="outline"
                        onClick={onRequestClose}
                        size="large"
                        testId={cancelButtonTestId}
                    />
                    <Button
                        text="OK"
                        variant="solid"
                        color="primary"
                        onClick={handleOkClick}
                        size="large"
                        testId={okButtonTestId}
                        isDisabled={isOkDisabled}
                    />
                </div>
            </ModalContent>
        </Modal>
    );
}
