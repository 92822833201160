import { StoredDataset, datasetSeries } from "@services/space/datasets/models";

interface IssueGroup {
    issue: string;
    datasets: string[];
}

export function FindConflictingDatasets(
    datasets: StoredDataset[],
    targetDataset: StoredDataset
): IssueGroup[] {
    const targetFrequencies = ExtractFrequencies(
        targetDataset.series,
        "angular_frequency"
    );
    const sortedTargetFrequencies = [...targetFrequencies].sort(
        (a, b) => a - b
    );

    const issueMap: Record<string, string[]> = {};

    datasets.forEach((dataset) => {
        const sampleFrequencies = ExtractFrequencies(
            dataset.series,
            "angular_frequency"
        );
        let issueMessage: string | null = null;

        if (sampleFrequencies.length !== targetFrequencies.length) {
            issueMessage =
                "The following dataset(s) have different number of frequency measurements than the target:";
        } else {
            const sortedSampleFrequencies = [...sampleFrequencies].sort(
                (a, b) => a - b
            );

            const mismatchFound = sortedTargetFrequencies.some((freq, i) => {
                const sampleFreq = sortedSampleFrequencies[i];

                const percentageDifference = Math.abs(freq - sampleFreq) / freq;

                return percentageDifference > 0.05;
            });

            if (mismatchFound) {
                issueMessage =
                    "The following dataset(s) have frequency values that do not match the target within a 5% tolerance:";
            }
        }

        if (issueMessage) {
            if (!issueMap[issueMessage]) {
                issueMap[issueMessage] = [];
            }
            issueMap[issueMessage].push(dataset.fileName);
        }
    });

    return Object.entries(issueMap).map(([issue, datasets]) => ({
        issue,
        datasets
    }));
}

function ExtractFrequencies(
    series: datasetSeries[],
    identifier: string
): number[] {
    const frequencySeries = series.find((s) =>
        s.metrics.some((metric) => metric.identifier === identifier)
    );

    if (!frequencySeries) {
        return [];
    }

    const index = frequencySeries.metrics.findIndex(
        (metric) => metric.identifier === identifier
    );

    return frequencySeries.dataPoints.map((point) =>
        typeof point[index] === "string"
            ? parseFloat(point[index] as string)
            : (point[index] as number)
    );
}
