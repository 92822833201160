import React, { useEffect, useState } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import { AlertBanner, Grid } from "@cpchem/covalence-ui";
import { AnalysisReportPage, DataSetsPage } from "../pages";
import { routePath } from "./route-paths";
import { SpaceAppBar } from "../components/space-app-bar";
import { useAuth } from "../hooks/use-auth";
import { setUserInLoggingContext } from "../logging/configure-logging";
import { spaceTrackEvent } from "../logging/space-track-event";
import { createPageViewEvent } from "@cpchem/logging";
import { USER_AUTHENTICATED } from "../logging/log-events";
import {
    FeatureFlagContext,
    FeatureFlagService
} from "@services/feature-flags/interface";
import { FeatureFlagServiceKey } from "@services/feature-flags";
import { useService } from "../service-provider";

export function App(): JSX.Element {
    const { authState, currentAccount } = useAuth();
    const [featureFlagContext, setFeatureFlagContext] =
        useState<FeatureFlagContext>();
    const featureFlagService = useService<FeatureFlagService>(
        FeatureFlagServiceKey
    );
    const location = useLocation();

    // track page view whenever the current location changes
    useEffect(() => {
        const path = location.pathname + location.search + location.hash;
        const pageView = createPageViewEvent(path, {
            ...location
        });
        spaceTrackEvent(pageView);
    }, [location]);

    useEffect(() => {
        if (authState === "Authenticated") {
            setUserInLoggingContext(currentAccount?.username ?? "anonymous");
            spaceTrackEvent({
                type: USER_AUTHENTICATED,
                contents: currentAccount?.username ?? "anonymous"
            });
        }

        // set feature flag context with info from authenticated user
        if (currentAccount) {
            featureFlagService
                .createContext(currentAccount)
                .then(setFeatureFlagContext);
        }
    }, [authState, currentAccount, featureFlagService]);

    const FeatureFlagProvider = featureFlagService.getProviderComponent();

    return (
        <FeatureFlagProvider context={featureFlagContext}>
            <Grid className="app">
                <SpaceAppBar />
                <AlertBanner />
                <div className="main">
                    <Routes>
                        <Route
                            path={routePath.DATASETS}
                            element={<DataSetsPage />}
                        />
                        <Route
                            path={routePath.REPORTPREVIEW}
                            element={<AnalysisReportPage />}
                        />
                        <Route
                            path={routePath.PLOTS}
                            element={<AnalysisReportPage />}
                        />
                    </Routes>
                </div>
            </Grid>
        </FeatureFlagProvider>
    );
}

export default App;
