import React from "react";
import { Checkbox, Select, Tooltip } from "@cpchem/covalence-ui";
import { StoredDataset } from "@services/space/datasets/models";

interface CompareDatasetsSectionProps {
    isComparingDatasets: boolean;
    selectedCompareDataset: string | null;
    selectedDatasets: StoredDataset[];
    tooltipText?: string;
    onToggleComparingDatasets: (checked: boolean) => void;
    onSelectCompareDataset: (value: string) => void;
    isRheologyComparison?: boolean;
    isDisabled?: boolean;
    testId?: string;
}

export function CompareDatasetsSection({
    isComparingDatasets,
    selectedCompareDataset,
    selectedDatasets,
    tooltipText,
    onToggleComparingDatasets,
    onSelectCompareDataset,
    isRheologyComparison,
    isDisabled,
    testId
}: CompareDatasetsSectionProps): JSX.Element {
    const options = selectedDatasets.map((d) => ({
        key: d.fileName,
        value: d.fileName,
        text: d.fileName
    }));

    if (isRheologyComparison && selectedDatasets.length > 0) {
        onToggleComparingDatasets(true);
    }

    const sectionTitle = isRheologyComparison
        ? "Target File"
        : "Compare Dataset";

    const selectBoxTestId = testId ? `${testId}-select` : "";

    const isCheckboxDisabled = options.length <= 1;
    return (
        <div
            className="parameter-section three-items compare-datasets"
            data-testid={testId}
        >
            <h4>{sectionTitle}</h4>
            {tooltipText ? (
                <Tooltip content={tooltipText} enterDelay="medium">
                    <div className="compare-dataset-checkbox">
                        {isRheologyComparison ? (
                            <></>
                        ) : (
                            <Checkbox
                                label=""
                                isChecked={isComparingDatasets}
                                onChange={onToggleComparingDatasets}
                                isDisabled={isDisabled || isCheckboxDisabled}
                                testId={`${testId}-checkbox`}
                            />
                        )}
                    </div>
                    <Select
                        className="compare-dataset-select"
                        label="Compare Datasets"
                        isLabelHidden
                        options={options}
                        value={selectedCompareDataset || ""}
                        onChange={onSelectCompareDataset}
                        isDisabled={!isComparingDatasets || isDisabled}
                        testId={selectBoxTestId}
                    />
                </Tooltip>
            ) : (
                <>
                    <div className="compare-dataset-checkbox">
                        {isRheologyComparison ? (
                            <></>
                        ) : (
                            <Checkbox
                                label=""
                                isChecked={isComparingDatasets}
                                onChange={onToggleComparingDatasets}
                                isDisabled={isDisabled || isCheckboxDisabled}
                                testId={`${testId}-checkbox`}
                            />
                        )}
                    </div>
                    <Select
                        className="compare-dataset-select"
                        label="Compare Datasets"
                        isLabelHidden
                        options={options}
                        value={selectedCompareDataset || ""}
                        onChange={onSelectCompareDataset}
                        isDisabled={!isComparingDatasets || isDisabled}
                        testId={selectBoxTestId}
                    />
                </>
            )}
        </div>
    );
}
