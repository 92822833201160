import { ApiResponse } from "@services";
import {
    AnalysisService,
    BaseAnalysisRequest,
    BaseAnalysisResponse
} from "@services/space/analysis/base-models";
import { RheologyAnalysisServiceImplementation } from "@services/space/analysis/cy-rheology/implementations";
import { RheologyComparisonServiceImplementation } from "@services/space/analysis/rheology-comparison/implementations";
import { RheologyTTSServiceImplementation } from "@services/space/analysis/cy-rheology/rheology-tts-implementations";
import { ApiErrorResponse } from "@services/api-response";
import { ViscosityAnalysisServiceImplementation } from "@services/space/analysis/viscosity/implementations";
import { ViscosityTTSServiceImplementation } from "@services/space/analysis/viscosity/viscosity-tts-implementations";
import { RheologyOverlayServiceImplementation } from "@services/space/analysis/rheology-overlay/implementations";

const analysisServiceDictionary: { [key: string]: () => AnalysisService } = {
    cyRheology: () => new RheologyAnalysisServiceImplementation(),
    cyRheologyTTS: () => new RheologyTTSServiceImplementation(),
    viscosity: () => new ViscosityAnalysisServiceImplementation(),
    viscosityTTS: () => new ViscosityTTSServiceImplementation(),
    rheologyComparison: () => new RheologyComparisonServiceImplementation(),
    rheologyOverlay: () => new RheologyOverlayServiceImplementation()
    // Add more analysis types and their corresponding services here
};

export const fetchAnalysisResponse = async <T extends BaseAnalysisResponse>(
    request: BaseAnalysisRequest
): Promise<ApiResponse<T> | ApiErrorResponse> => {
    const serviceFactory =
        analysisServiceDictionary[
            request.analysisType as keyof typeof analysisServiceDictionary
        ];

    if (!serviceFactory) {
        throw new Error(`Unsupported analysis type: ${request.analysisType}`);
    }

    const service = serviceFactory();

    // console.log("API Request:", request);
    try {
        const response = await service.fetchAnalysisResults(request);
        // console.log("API Response:", response);
        return response;
    } catch (error) {
        console.error("Error performing analysis:", error);
        throw error;
    }
};
