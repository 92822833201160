import React from "react";
import { StoredDataset } from "@services/space/datasets/models";
import {
    formatTemperatures,
    getSampleName,
    getSubmittedOnDate,
    getTemperatures
} from "../../../../utilities/dataset-utils/dataset-utils";

interface MetaDataSectionProps {
    dataset: StoredDataset;
    testId?: string;
}

export function MetaDataSection({
    dataset,
    testId
}: MetaDataSectionProps): JSX.Element {
    const fileName = dataset.fileName || "";
    const temperature = formatTemperatures(getTemperatures(dataset));
    const sampleId = getSampleName(dataset);
    const dateRan = getSubmittedOnDate(dataset) || "Not Avaliable";

    const tableTestId = testId ? `${testId}-table` : undefined;
    const fileNameTestId = testId ? `${testId}-file-name` : undefined;
    const temperatureTestId = testId ? `${testId}-temperature` : undefined;
    const sampleIdTestId = testId ? `${testId}-sample-id` : undefined;
    const dateRanTestId = testId ? `${testId}-date-ran` : undefined;

    return (
        <div className="plot-overlay-metadata-section" data-testid={testId}>
            <h2 className="section-title">Metadata</h2>
            <table
                className="plot-overlay-metadata-table"
                data-testid={tableTestId}
            >
                <tbody>
                    <tr>
                        <th className="plot-overlay-metadata-table-header">
                            File Name
                        </th>
                        <td data-testid={fileNameTestId}>{fileName}</td>
                        <th className="plot-overlay-metadata-table-header">
                            Temperature
                        </th>
                        <td data-testid={temperatureTestId}>{temperature}</td>
                    </tr>
                    <tr>
                        <th className="plot-overlay-metadata-table-header">
                            Sample ID
                        </th>
                        <td data-testid={sampleIdTestId}>{sampleId}</td>
                        <th className="plot-overlay-metadata-table-header">
                            Date Ran
                        </th>
                        <td data-testid={dateRanTestId}>{dateRan}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
