import React, { useState } from "react";
import { PlotData } from "plotly.js";
import { AnalysisResponseType } from "@stores/datasets/datasets-types";
import {
    StyleAssignment,
    StyleAssignments
} from "../../../utilities/constants/chart-colors";
import { spaceTrackEvent } from "../../../logging/space-track-event";
import { LOADING_USER_REPORT } from "../../../logging/log-events";
import { Carousel } from "../../../components/carousel/carousel";
import { OverlayPlotType } from "../../../interfaces/analysis-types";
import {
    PlotAnalysisSampleInfo,
    PlotAnalysisSamplePlotSeriesCollection,
    PlotAnalysisSamplePlotSeriesInfo,
    DELTA_PLOT,
    LOSS_PLOT,
    STORAGE_PLOT,
    VISCOSITY_PLOT
} from "@services/space/analysis/rheology-overlay/models";
import RheologyOverlayPlot from "./rheology-overlay-plot";

export interface IRheologyOverlayPreviewChartDataProviderProps {
    RawData: AnalysisResponseType;
    selectedPlotTypes: OverlayPlotType[];
    testId?: string;
}

const RheologyOverlayPreviewChartDataProvider: React.FC<
    IRheologyOverlayPreviewChartDataProviderProps
> = (props: IRheologyOverlayPreviewChartDataProviderProps) => {
    const { RawData, selectedPlotTypes, testId } = props;
    const styleAssignments: StyleAssignment[] = StyleAssignments;

    const ViscosityPlotData: PlotData[] = [];
    const StoragePlotData: PlotData[] = [];
    const LossPlotData: PlotData[] = [];
    const DeltaPlotData: PlotData[] = [];

    const plots: JSX.Element[] = [];
    const carouselLabels: string[] = [];

    const getSeriesDataFromRawDataForOverlay = (
        RawData: AnalysisResponseType
    ): void => {
        const response = RawData as AnalysisResponseType;
        const samples = response.rheologyOverlay!.samples!;
        const SelectedPlotTypeNames = selectedPlotTypes.map((plot) => plot.key);
        const extrapolatePlotData = (
            plotType: string,
            seriesArray: PlotData[]
        ): void => {
            if (SelectedPlotTypeNames.includes(plotType)) {
                const tempArray: string[] = [];
                for (let i = 0; i < samples.length; i++) {
                    const series = GetSeriesDataFromSampleForPlotType(
                        samples[i],
                        i,
                        plotType
                    );
                    if (series) {
                        series.forEach((serie) => seriesArray.push(serie));
                        GetTemps(samples[i], plotType).forEach((temp) =>
                            tempArray.push(temp)
                        );
                    }
                }
                const selectedPlotType = selectedPlotTypes.filter(
                    (plot) => plot.key === plotType
                )![0];
                const title =
                    selectedPlotType.displayLabel ??
                    selectedPlotType.optionLabel;
                carouselLabels.push(title);
                plots.push(
                    <RheologyOverlayPlot
                        key={plotType}
                        title={title}
                        plotType={plotType}
                        data={seriesArray}
                        temp={[...new Set(tempArray)].join(", ")}
                        testId={`${testId}-${plotType}`}
                    />
                );
            }
        };
        extrapolatePlotData(VISCOSITY_PLOT, ViscosityPlotData);
        extrapolatePlotData(DELTA_PLOT, DeltaPlotData);
        extrapolatePlotData(STORAGE_PLOT, StoragePlotData);
        extrapolatePlotData(LOSS_PLOT, LossPlotData);
    };

    const GetTemps = (
        sample: PlotAnalysisSampleInfo,
        plotType: string
    ): string[] => {
        const temps: string[] = [];
        const plotData: PlotAnalysisSamplePlotSeriesCollection =
            sample.plots[plotType];
        if (plotData) {
            const { temperatures } = plotData.summary;
            if (temperatures) {
                temperatures.forEach((temp) => temps.push(`${temp}`));
            }
        }
        return temps;
    };

    const GetSeriesDataFromSampleForPlotType = (
        sample: PlotAnalysisSampleInfo,
        index: number,
        plotType: string
    ): PlotData[] | null => {
        const lastStyleAssignmentIndex: number = styleAssignments.length - 1;
        const styleAssignmentIndex: number =
            index < styleAssignments.length ? index : lastStyleAssignmentIndex;
        const { color, symbol, dash } = styleAssignments[styleAssignmentIndex];
        const width = 2;
        const shape = "spline";

        const plotData: PlotAnalysisSamplePlotSeriesCollection =
            sample.plots[plotType];
        if (plotData) {
            const responseSeries: PlotAnalysisSamplePlotSeriesInfo[] =
                plotData.series;
            const plotSeries: PlotData[] = [];
            responseSeries.forEach((serie) => {
                const { name, xValues, yValues } = serie;

                if (name === "measured" || name === null) {
                    plotSeries.push({
                        x: xValues,
                        y: yValues,
                        type: "scatter",
                        mode:
                            plotType === VISCOSITY_PLOT
                                ? "markers"
                                : "lines+markers",
                        name: `${sample.name}`,
                        marker: { color, symbol },
                        line: { color, width, shape, dash }
                    } as PlotData);
                }

                if (name === "modeled") {
                    plotSeries.push({
                        x: xValues,
                        y: yValues,
                        type: "scatter",
                        mode: "lines",
                        name: `${sample.name} CY Model`,
                        marker: { color },
                        line: { color, width, shape, dash }
                    } as PlotData);
                }
            });
            return plotSeries;
        }
        return null;
    };

    const [activeItemIndex, setActiveItemIndex] = useState(0);

    spaceTrackEvent({
        type: LOADING_USER_REPORT,
        contents: JSON.stringify({ rawData: RawData })
    });

    getSeriesDataFromRawDataForOverlay(RawData);

    return (
        <div>
            {plots.length > 1 && (
                <Carousel
                    label={carouselLabels[activeItemIndex]}
                    testId={testId}
                    initialActiveItemIndex={0}
                    onActiveItemIndexChange={(idx) => setActiveItemIndex(idx)}
                >
                    {plots}
                </Carousel>
            )}
            {plots.length === 1 && plots}
        </div>
    );
};

export default RheologyOverlayPreviewChartDataProvider;
