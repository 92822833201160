import { StoredDataset } from "@services/space/datasets/models";
import { FindConflictingDatasets } from "../../../utilities/validation/datasets-validation";
import { CyRheologyParameters } from "../../../interfaces/analysis-types";

const validDataTypes = ["frequencySweep", "tempFreqSweep"];
const validResinTypes = ["PE", "PP", "PS", "Other"];

export function ValidateCyRheologyParameters(
    params: CyRheologyParameters
): boolean {
    const isValidDatasets = ValidateDatasets(params.selectedDatasets.length);
    const isValidResinType = ValidateResinType(params.resinType);
    const isValidComparison = ValidateComparison(
        params.isComparingDatasets,
        params.selectedCompareDataset,
        params.selectedDatasets
    );
    const isValidDataType = ValidateDataType(params.dataType);
    const isValidNValue = ValidateNValue(params.fixN, params.nValue);
    const isValidVogelTemp = ValidateVogelTemp(params.vogelTemp);
    const isValidReferenceTemp = ValidateReferenceTemp(params.referenceTemp);
    const { isValid: isValidTargetComparison } = ValidateTargetComparison(
        params.isComparingDatasets,
        params.selectedCompareDataset,
        params.selectedDatasets
    );

    return (
        isValidDatasets &&
        isValidResinType &&
        isValidComparison &&
        isValidDataType &&
        isValidNValue &&
        isValidVogelTemp &&
        isValidReferenceTemp &&
        isValidTargetComparison
    );
}

export function ValidateDatasets(datasets: number): boolean {
    return datasets > 0;
}

export function ValidateDataType(dataType: string): boolean {
    return validDataTypes.includes(dataType);
}

export function ValidateNValue(fixN: boolean, nValue: string | null): boolean {
    if (fixN) {
        if (
            nValue === null ||
            (typeof nValue === "string" && nValue.trim() !== "")
        ) {
            return !Number.isNaN(parseFloat(nValue as string));
        }
        return false;
    }
    return true;
}

export function ValidateVogelTemp(vogelTemp: string | null): boolean {
    return !Number.isNaN(parseFloat(vogelTemp as string));
}

export function ValidateReferenceTemp(referenceTemp: string | null): boolean {
    return !Number.isNaN(parseFloat(referenceTemp as string));
}

export function ValidateComparison(
    isComparingDatasets: boolean,
    selectedCompareDataset: string | null,
    selectedDatasets: StoredDataset[]
): boolean {
    if (isComparingDatasets && selectedCompareDataset) {
        if (selectedDatasets.length < 2) {
            return false;
        }
    }
    return true;
}

export function ValidateResinType(resinType: string): boolean {
    return validResinTypes.includes(resinType);
}

export function ValidateTargetComparison(
    isComparingDatasets: boolean,
    selectedCompareDataset: string | null,
    selectedDatasets: StoredDataset[]
): {
    isValid: boolean;
    conflictGroups: { issue: string; datasets: string[] }[];
} {
    if (isComparingDatasets && selectedCompareDataset) {
        const targetDataset = selectedDatasets.find(
            (dataset) => dataset.fileName === selectedCompareDataset
        );
        if (!targetDataset) {
            return {
                isValid: false,
                conflictGroups: [
                    { issue: "Target dataset not found.", datasets: [] }
                ]
            };
        }

        const conflicts = FindConflictingDatasets(
            selectedDatasets,
            targetDataset
        );
        if (conflicts.length > 0) {
            return { isValid: false, conflictGroups: conflicts };
        }
    }

    return { isValid: true, conflictGroups: [] };
}
