import { StoredDataset } from "@services/space/datasets/models";
import { OverlayPlotType, PlotTypes } from "../../../interfaces/analysis-types";
import {
    DELTA_PLOT,
    LOSS_PLOT,
    STORAGE_PLOT,
    VISCOSITY_PLOT
} from "@services/space/analysis/rheology-overlay/models";

export function hasInvalidPlotSelections(
    selectedPlots: OverlayPlotType[],
    disabledPlots: OverlayPlotType[]
): boolean {
    const invalidItems = selectedPlots.filter((plot) =>
        disabledPlots.includes(plot)
    );
    return invalidItems.length > 0;
}

export function GetUnavailablePlots(
    selectedDatasets: StoredDataset[]
): OverlayPlotType[] {
    const unavailablePlots: OverlayPlotType[] = [];
    if (
        selectedDatasets.every((dataset) => !CanProduceViscosityPlot(dataset))
    ) {
        unavailablePlots.push(
            PlotTypes.find((plot) => plot.key === VISCOSITY_PLOT)!
        );
    }
    if (selectedDatasets.every((dataset) => !CanProduceDeltaPlot(dataset))) {
        unavailablePlots.push(
            PlotTypes.find((plot) => plot.key === DELTA_PLOT)!
        );
    }
    if (selectedDatasets.every((dataset) => !CanProduceStoragePlot(dataset))) {
        unavailablePlots.push(
            PlotTypes.find((plot) => plot.key === STORAGE_PLOT)!
        );
    }
    if (selectedDatasets.every((dataset) => !CanProduceLossPlot(dataset))) {
        unavailablePlots.push(
            PlotTypes.find((plot) => plot.key === LOSS_PLOT)!
        );
    }

    return unavailablePlots;
}

export function CanProduceViscosityPlot(dataset: StoredDataset): boolean {
    const identifier = "complex_shear_viscosity";
    const hasData =
        dataset.series.filter((series) =>
            series.metrics.some((attr) => attr.identifier === identifier)
        ).length > 0;
    return hasData;
}

export function CanProduceDeltaPlot(dataset: StoredDataset): boolean {
    const identifier = "loss_tangent";
    const hasData =
        dataset.series.filter((series) =>
            series.metrics.some((attr) => attr.identifier === identifier)
        ).length > 0;
    return hasData;
}

export function CanProduceStoragePlot(dataset: StoredDataset): boolean {
    const identifier = "shear_storage_modulus";
    const hasData =
        dataset.series.filter((series) =>
            series.metrics.some((attr) => attr.identifier === identifier)
        ).length > 0;
    return hasData;
}

export function CanProduceLossPlot(dataset: StoredDataset): boolean {
    const identifier = "shear_loss_modulus";
    const hasData =
        dataset.series.filter((series) =>
            series.metrics.some((attr) => attr.identifier === identifier)
        ).length > 0;
    return hasData;
}

export function setDatasetValidationFlags(
    selectedDatasets: StoredDataset[],
    selectedPlots: OverlayPlotType[]
): void {
    selectedDatasets.forEach((dataset) => {
        let isvalid = true;
        const selectedPlotKeys = selectedPlots.map((plot) => plot.key);
        if (
            selectedPlotKeys.includes(VISCOSITY_PLOT) &&
            !CanProduceViscosityPlot(dataset)
        ) {
            isvalid = false;
        }
        if (
            selectedPlotKeys.includes(DELTA_PLOT) &&
            !CanProduceDeltaPlot(dataset)
        ) {
            isvalid = false;
        }
        if (
            selectedPlotKeys.includes(STORAGE_PLOT) &&
            !CanProduceStoragePlot(dataset)
        ) {
            isvalid = false;
        }
        if (
            selectedPlotKeys.includes(LOSS_PLOT) &&
            !CanProduceLossPlot(dataset)
        ) {
            isvalid = false;
        }
        dataset.isValid = isvalid;
    });
}
