import React, { useState } from "react";
import { Button, TextField } from "@cpchem/covalence-ui";

interface AliasSectionProps {
    alias: string;
    externalError?: string;
    onAliasChange: (newAlias: string, isRegexValid: boolean) => void;
    testId?: string;
}

export function AliasSection({
    alias,
    externalError,
    onAliasChange,
    testId
}: AliasSectionProps): JSX.Element {
    const [localRegexError, setLocalRegexError] = useState("");

    const handleInputChange = (newValue: string) => {
        const trimmed = newValue.replace(/^\s+|\s+$/g, "");

        // Regex: letters, digits, spaces, hyphens, and underscores only
        const regex = /^[A-Za-z0-9\s-_]*$/;
        let errorMsg = "";

        if (!regex.test(trimmed)) {
            errorMsg =
                "Only letters, digits, spaces, hyphens, and underscores are allowed.";
        }

        setLocalRegexError(errorMsg);
        onAliasChange(trimmed, !errorMsg);
    };

    const handleClearAlias = () => {
        setLocalRegexError("");
        onAliasChange("", true);
    };
    const combinedError = localRegexError || externalError || "";

    return (
        <div className="plot-overlay-alias-section" data-testid={testId}>
            <h2 className="section-title">Alias</h2>
            <div className="alias-input-container">
                <TextField
                    label="Alias"
                    isLabelHidden
                    placeholder="A Unique Name"
                    value={alias}
                    onChange={handleInputChange}
                    testId={testId ? `${testId}-text-field` : undefined}
                    errorMessage={combinedError}
                    description={
                        combinedError
                            ? ""
                            : "Only alphanumeric characters, spaces, hyphens, and underscores are allowed"
                    }
                />

                <Button
                    className="clear-alias-button"
                    onClick={handleClearAlias}
                    color="primary"
                    text={"Clear Alias"}
                    testId={testId ? `${testId}-clear-alias` : undefined}
                    isDisabled={!alias}
                />
            </div>
        </div>
    );
}
