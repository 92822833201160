import { ApiErrorResponse, ApiResponse } from "@services/api-response";
import { PlotTypesServiceImplementation } from "@services/space/plotTypes/implementations";
import { PlotTypesResponse } from "@services/space/plotTypes/models";

export const fetchPlotTypes = async (): Promise<
    {
        key: string;
        name: string | null;
    }[]
> => {
    try {
        const plotTypesService = new PlotTypesServiceImplementation();
        const response = await plotTypesService.getAllPlotTypes();

        if ("data" in response) {
            const plotTypesData = (
                response as ApiResponse<PlotTypesResponse>
            ).data.plotTypes.map((item) => ({
                key: item.key,
                name: item.name
            }));

            return plotTypesData;
        }

        console.error(
            "Failed to fetch plot types:",
            (response as ApiErrorResponse).title
        );
        return [];
    } catch (error) {
        console.error("Error fetching plot types:", error);
        return [];
    }
};
