import React from "react";
import { StoredDataset } from "@services/space/datasets/models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import {
    CanProduceDeltaPlot,
    CanProduceLossPlot,
    CanProduceStoragePlot,
    CanProduceViscosityPlot
} from "../../../report-parameters/rheology-overlay/validations";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface DataValidationSectionProps {
    dataset: StoredDataset;
    testId?: string;
}

export function DataValidationSection({
    dataset,
    testId
}: DataValidationSectionProps): JSX.Element {
    const checks = [
        {
            label: "Dynamic Melt Viscosity vs. Frequency",
            pass: CanProduceViscosityPlot(dataset)
        },
        {
            label: "Loss Modulus vs. Frequency",
            pass: CanProduceLossPlot(dataset)
        },
        {
            label: "Storage Modulus vs. Frequency",
            pass: CanProduceStoragePlot(dataset)
        },
        {
            label: "Delta vs. Complex Modulus",
            pass: CanProduceDeltaPlot(dataset)
        }
    ];

    return (
        <div
            className="plot-overlay-data-validation-section"
            data-testid={testId}
        >
            <h2 className="section-title">Data Validation</h2>
            <p>
                The following types of plots may be generated from this dataset:
            </p>

            <ul className="plot-validation-list">
                {checks.map((check) => (
                    <li
                        key={check.label}
                        className="plot-validation-item"
                        data-testid={`${testId}-${check.label}`}
                    >
                        {check.label}
                        {check.pass ? (
                            <FontAwesomeIcon
                                icon={faCheckCircle as IconProp}
                                className="plot-validation-icon pass"
                                data-testid={`${testId}-${check.label}-pass`}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faTimesCircle as IconProp}
                                className="plot-validation-icon fail"
                                data-testid={`${testId}-${check.label}-fail`}
                            />
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}
