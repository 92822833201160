import React from "react";
import { Checkbox } from "@cpchem/covalence-ui";
import { OverlayPlotType } from "../../../../interfaces/analysis-types";

interface PlotsSectionProps {
    initiallySelectedOptions: OverlayPlotType[];
    plotOptions: OverlayPlotType[];
    disabledPlots: OverlayPlotType[];
    updateSelections: (selections: OverlayPlotType[]) => void;
    testId: string | undefined;
}

export function PlotsSection({
    initiallySelectedOptions,
    plotOptions,
    disabledPlots,
    updateSelections,
    testId
}: PlotsSectionProps): JSX.Element {
    const isPlotSelected = (plot: OverlayPlotType): boolean =>
        initiallySelectedOptions.some(
            (selectedPlot) => selectedPlot.key === plot.key
        );

    const isPlotDisabled = (plot: OverlayPlotType): boolean =>
        disabledPlots.some((disabledPlot) => disabledPlot.key === plot.key);

    const handleUpdateSelection = (
        selectedPlot: OverlayPlotType,
        isChecked: boolean
    ): void => {
        let updatedSelections: OverlayPlotType[];
        if (isChecked) {
            updatedSelections = [...initiallySelectedOptions, selectedPlot];
        } else {
            updatedSelections = initiallySelectedOptions.filter(
                (plot) => plot.key !== selectedPlot.key
            );
        }
        updateSelections(updatedSelections);
    };

    const renderCheckboxOptions = (plots: OverlayPlotType[]) => {
        return plots.map((plot) => (
            <div className="compare-dataset-checkbox" key={plot.key}>
                <Checkbox
                    label={plot.displayLabel ?? plot.optionLabel}
                    isChecked={isPlotSelected(plot)}
                    onChange={(isChecked) =>
                        handleUpdateSelection(plot, isChecked)
                    }
                    isDisabled={isPlotDisabled(plot)}
                    testId={`${testId}-${plot.key}`}
                />
            </div>
        ));
    };

    return (
        <div className="parameter-section">
            {renderCheckboxOptions(plotOptions)}
        </div>
    );
}
