import React, { useState, useEffect } from "react";
import { BaseCyRheologyForm } from "../cy-rheology/base-cy-rheology-form";
import { DataTypeSection } from "../cy-rheology/components/data-type-section";
import { FixNSection } from "../cy-rheology/components/fix-n-section";
import { ReferenceTempSection } from "../cy-rheology/components/reference-temp-section";
import { YieldStressTermSection } from "../cy-rheology/components/yield-stress-term-section";
import {
    ViscosityParameters,
    CyRheologyParametersForViscosityAnalysis
} from "../../../interfaces/analysis-types";
import {
    ValidateCyRheologyParametersForViscosity,
    ValidateDatasetsOrGroups
} from "./viscosity-validations";
import { SpaceAccordion } from "../../space-accordion/space-accordion";
import { Divider } from "@cpchem/covalence-ui";
import { DatasetsSection } from "../cy-rheology/components/datasets-section";
import { ViscosityParametersSection } from "./components/viscosity-parameters-section";
import { initialResinDataMap } from "./utils/initial-resin-data";
import { fetchResinData } from "./utils/fetch-resin-data";
import _ from "lodash";
import "../styles.scss";
import { VogelTempSection } from "../cy-rheology/components/vogel-temp-section";

interface ViscosityFormProps {
    initialParameters: ViscosityParameters;
    onUpdate: (updates: Partial<ViscosityParameters>) => void;
    onValidation: (isValid: boolean) => void;
    isLoading?: boolean;
    testId?: string;
}

export function ViscosityForm({
    initialParameters,
    onUpdate,
    onValidation,
    isLoading,
    testId
}: ViscosityFormProps): JSX.Element {
    const [openAccordion, setOpenAccordion] = useState<string | null>(
        "Datasets"
    );
    const [parameters, setParameters] =
        useState<ViscosityParameters>(initialParameters);

    const [isDatasetsValid, setIsDatasetsValid] = useState<boolean>(false);
    const [isRheologyValid, setIsRheologyValid] = useState<boolean>(true);
    const [isViscosityParamsValid, setIsViscosityParamsValid] =
        useState<boolean>(false);

    const [resinDataMap, setResinDataMap] = useState(initialResinDataMap);

    useEffect(() => {
        const fetchData = async () => {
            const resinTypes = await fetchResinData();
            setResinDataMap((prevData) => ({
                ...prevData,
                ...resinTypes
            }));
        };

        fetchData();
    }, []);

    useEffect(() => {
        const isValid =
            isDatasetsValid && isRheologyValid && isViscosityParamsValid;
        onValidation(isValid);
    }, [
        isDatasetsValid,
        isRheologyValid,
        isViscosityParamsValid,
        onValidation
    ]);

    const handleUpdate = (updates: Partial<ViscosityParameters>) => {
        setParameters((prevParameters) => {
            const updatedParameters = _.cloneDeep({
                ...prevParameters,
                ...updates
            });
            onUpdate(updatedParameters);
            return updatedParameters;
        });
    };

    useEffect(() => {
        const isValid = ValidateDatasetsOrGroups(
            parameters.selectedDatasets,
            parameters.selectedDatasetGroups
        );
        setIsDatasetsValid(isValid);
    }, [parameters.selectedDatasets, parameters.selectedDatasetGroups]);

    return (
        <div className="parameters-content viscosity-form">
            <SpaceAccordion
                label="Datasets"
                isOpen={openAccordion === "Datasets"}
                onToggle={() =>
                    setOpenAccordion(
                        openAccordion === "Datasets" ? null : "Datasets"
                    )
                }
                defaultOpen
                testId={`${testId}-datasets-accordion`}
            >
                <DatasetsSection
                    selectedDatasetGroups={parameters.selectedDatasetGroups}
                    onUpdateSelectedDatasetGroups={(datasetGroups) => {
                        handleUpdate({ selectedDatasetGroups: datasetGroups });
                    }}
                    isLoading={isLoading}
                    isGroupedDatasets
                    isOverlay={false}
                    testId={`${testId}-datasets-section`}
                />
            </SpaceAccordion>
            <Divider />

            <SpaceAccordion
                label="Rheology Parameters"
                isOpen={openAccordion === "Rheology Parameters"}
                onToggle={() =>
                    setOpenAccordion(
                        openAccordion === "Rheology Parameters"
                            ? null
                            : "Rheology Parameters"
                    )
                }
                testId={`${testId}-rheology-parameters-accordion`}
            >
                <BaseCyRheologyForm<CyRheologyParametersForViscosityAnalysis>
                    initialParameters={parameters.cyRheologyParams}
                    onUpdate={(updates) =>
                        handleUpdate({
                            cyRheologyParams: {
                                ...parameters.cyRheologyParams,
                                ...updates
                            }
                        })
                    }
                    onValidation={setIsRheologyValid}
                    isLoading={isLoading}
                    validate={ValidateCyRheologyParametersForViscosity}
                >
                    {({
                        parameters: rheologyParams,
                        handleFieldUpdate,
                        isLoading
                    }) => (
                        <>
                            <DataTypeSection
                                dataType={rheologyParams.dataType}
                                onChange={(value) => {
                                    handleFieldUpdate("dataType", value);
                                    if (value === "frequencySweep") {
                                        handleFieldUpdate("vogelTemp", "0.0");
                                    }
                                }}
                                isDisabled={isLoading}
                            />
                            <FixNSection
                                fixN={rheologyParams.fixN}
                                nValue={rheologyParams.nValue}
                                onFixNChange={(value) =>
                                    handleFieldUpdate("fixN", value)
                                }
                                onNValueChange={(value) =>
                                    handleFieldUpdate("nValue", value)
                                }
                                isDisabled={isLoading}
                            />
                            <VogelTempSection
                                vogelTemp={rheologyParams.vogelTemp}
                                onChange={(value) =>
                                    handleFieldUpdate("vogelTemp", value)
                                }
                                isDisabled={
                                    isLoading ||
                                    rheologyParams.dataType === "frequencySweep"
                                }
                            />
                            <ReferenceTempSection
                                referenceTemp={rheologyParams.referenceTemp}
                                onChange={(value) =>
                                    handleFieldUpdate("referenceTemp", value)
                                }
                                isDisabled={isLoading}
                            />
                            <YieldStressTermSection
                                isUsingYieldStressTerm={
                                    rheologyParams.isUsingYieldStressTerm
                                }
                                onChange={(checked) =>
                                    handleFieldUpdate(
                                        "isUsingYieldStressTerm",
                                        checked
                                    )
                                }
                                isDisabled={isLoading}
                            />
                        </>
                    )}
                </BaseCyRheologyForm>
            </SpaceAccordion>
            <Divider />
            <SpaceAccordion
                label="Viscosity Parameters"
                isOpen={openAccordion === "Viscosity Parameters"}
                onToggle={() =>
                    setOpenAccordion(
                        openAccordion === "Viscosity Parameters"
                            ? null
                            : "Viscosity Parameters"
                    )
                }
                testId={`${testId}-viscosity-parameters-accordion`}
            >
                <ViscosityParametersSection
                    parameters={parameters}
                    resinDataMap={resinDataMap}
                    onUpdate={(updates) => handleUpdate(updates)}
                    onValidation={setIsViscosityParamsValid}
                    isLoading={isLoading}
                    testId={`${testId}-viscosity-parameters-section`}
                />
            </SpaceAccordion>
        </div>
    );
}
