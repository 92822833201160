import { StoredDataset } from "@services/space/datasets/models";

export function getSampleName(dataset: StoredDataset): string {
    const allAttributes = (dataset.metadata || []).flatMap(
        (m) => m.attributes || []
    );

    const foundAttr = allAttributes.find(
        (attr) => attr.identifier === "sample_name" && attr.value
    );

    return foundAttr ? String(foundAttr.value) : "";
}

export function getSubmittedOnDate(dataset: StoredDataset): string | null {
    if (dataset.sourceFormat !== "trios_rad") {
        return null;
    }

    const allAttributes = (dataset.metadata || []).flatMap(
        (m) => m.attributes || []
    );

    const foundAttr = allAttributes.find(
        (attr) => attr.title === "Sample Submitted On" && attr.value
    );

    return foundAttr ? String(foundAttr.value) : null;
}

function parseTemperaturesFromString(text: string): string[] {
    // Global regex to capture multiple occurrences of (number + optional decimal) followed by "C"
    // e.g., "185.5C" or "190C"
    const regex = /(\d+(\.\d+)?)\s*C/gi;
    const results: string[] = [];
    let match: RegExpExecArray | null;

    while ((match = regex.exec(text)) !== null) {
        results.push(match[1]);
    }
    return results;
}

function findTemperaturesInMetadata(dataset: StoredDataset): string[] {
    const allAttributes = (dataset.metadata || []).flatMap(
        (m) => m.attributes || []
    );

    const foundTemps: string[] = [];

    allAttributes.forEach((attr) => {
        if (typeof attr.value === "string") {
            const temps = parseTemperaturesFromString(attr.value);
            if (temps.length > 0) {
                foundTemps.push(...temps);
            }
        }
    });

    return foundTemps;
}

/**
 * Identify any "Temperature" metrics in the data points, gather *all* temperature values,
 * then group them by closeness (threshold = 5 by default).
 *
 * Steps:
 * 1. Find columns whose identifier or title is "temperature".
 * 2. Collect all numeric temperature data from `dataPoints`.
 * 3. Group them so that if a new reading is > threshold away from the group's average, we start a new group.
 * 4. Take each group, average it, then round up with Math.ceil, returning those as strings.
 */
function findTemperaturesInDataPoints(
    dataset: StoredDataset,
    threshold = 5
): string[] {
    if (!dataset.series?.length) {
        return [];
    }

    const tempValues: number[] = [];
    dataset.series.forEach((s) => {
        s.metrics.forEach((metric, idx) => {
            const isTemp =
                (metric.identifier &&
                    metric.identifier.toLowerCase() === "temperature") ||
                (metric.title && metric.title.toLowerCase() === "temperature");

            if (isTemp && s.dataPoints?.length) {
                s.dataPoints.forEach((row) => {
                    const val = row[idx];
                    if (typeof val === "number") {
                        tempValues.push(val);
                    }
                });
            }
        });
    });

    if (tempValues.length === 0) {
        return [];
    }

    tempValues.sort((a, b) => a - b);

    const groups: number[][] = [];
    let currentGroup: number[] = [];

    const getAverage = (nums: number[]): number =>
        nums.reduce((sum, v) => sum + v, 0) / nums.length;

    tempValues.forEach((tempVal) => {
        if (currentGroup.length === 0) {
            currentGroup.push(tempVal);
            return;
        }

        const avg = getAverage(currentGroup);
        const diff = Math.abs(tempVal - avg);

        if (diff <= threshold) {
            currentGroup.push(tempVal);
        } else {
            groups.push(currentGroup);
            currentGroup = [tempVal];
        }
    });

    if (currentGroup.length > 0) {
        groups.push(currentGroup);
    }

    const results = groups.map((g) => {
        const avg = getAverage(g);
        return Math.round(avg).toString();
    });

    return results;
}

export function getTemperatures(dataset: StoredDataset): string[] {
    const dpTemps = findTemperaturesInDataPoints(dataset);
    if (dpTemps.length > 0) {
        return dpTemps;
    }

    const metaTemps = findTemperaturesInMetadata(dataset);
    if (metaTemps.length > 0) {
        return metaTemps;
    }

    return [];
}

export function formatTemperatures(temps: string[]): string {
    return temps
        .map((temp, index) => {
            if (index === temps.length - 1) {
                return `${temp} °C`;
            }
            return `${temp}, `;
        })
        .join("");
}

export function FormatDate(date: string | null) {
    if (date === null) {
        return null;
    }
    const inputDate = new Date(date);
    const padStart = (value: number): string =>
        value.toString().padStart(2, "0");
    return `${inputDate.getFullYear()}-${padStart(inputDate.getMonth() + 1)}-${padStart(inputDate.getDate() + 1)}`;
}
