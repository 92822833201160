import Plot from "react-plotly.js";
import React, { useState } from "react";
import { PlotData, Layout } from "plotly.js";
import {
    DELTA_PLOT,
    LOSS_PLOT,
    STORAGE_PLOT,
    VISCOSITY_PLOT
} from "@services/space/analysis/rheology-overlay/models";

export interface ICYGraphProps {
    title: string;
    plotType: string;
    data: PlotData[];
    temp: string | null;
    testId?: string;
}

const RheologyOverlayPlot: React.FC<ICYGraphProps> = (props: ICYGraphProps) => {
    const { title, plotType, data, temp, testId } = props;
    const [visibility, setVisibility] = useState<boolean[]>(
        data.map(() => true)
    );
    const handleLegendClick = (index: number) => {
        const newVisibility = [...visibility];
        newVisibility[index] = !newVisibility[index];
        setVisibility(newVisibility);
    };

    function GetYAxisLabel(plotType: string): string {
        switch (plotType) {
            case VISCOSITY_PLOT:
                return "Viscosity (Pa·s)";
            case DELTA_PLOT:
                return "Loss Tangent (°)";
            case STORAGE_PLOT:
                return "Storage Modulus (Pa)";
            case LOSS_PLOT:
                return "Loss Modulus (Pa)";
            default:
                return "N/A";
        }
    }

    const tempSubTitle =
        temp !== null && temp !== ""
            ? `<sup>Measured at <span id="analysis-temp">${temp}</span>°C</sup>`
            : "";

    const layout: Partial<Layout> = {
        title: `${title}<br>${tempSubTitle}`,
        xaxis: {
            title: {
                text: "Angular Frequency (rad/s)",
                standoff: 0
            },
            dtick: 1,
            type: "log",
            autorange: true,
            exponentformat: "power",
            automargin: true
        },
        yaxis: {
            title: GetYAxisLabel(plotType),
            type: plotType === DELTA_PLOT ? "-" : "log",
            dtick: plotType === DELTA_PLOT ? 10 : 1,
            autorange: true,
            exponentformat: "power",
            automargin: true
        },
        autosize: false,
        showlegend: true,
        legend: { orientation: "h", yref: "container", itemwidth: 60 },
        width: 800,
        height: 550
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleLegendClickPlotly = (eventData: any) => {
        if (eventData.curveNumber !== undefined) {
            handleLegendClick(eventData.curveNumber);
        }
    };

    const previewChartTestId = testId ? `${testId}-preview-chart` : undefined;

    return (
        <div className="plot-wrapper" data-testid={previewChartTestId}>
            <Plot
                divId="preview-chart"
                data={data}
                layout={layout}
                className="plotly-chart-size"
                useResizeHandler={false}
                onClick={handleLegendClickPlotly}
            />
        </div>
    );
};

export default RheologyOverlayPlot;
