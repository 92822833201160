import React, { useState, useEffect, useRef } from "react";
import { Button, Card, DataGrid, Menu, useToast } from "@cpchem/covalence-ui";
import { faX, faCloudArrowUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    useDatasetManagement,
    postConvertDatasetFailure,
    postFetchLocalUserDatasetsFailure,
    setDataFormats,
    getDatasetFormats
} from "../../../stores/datasets";
import { useService } from "../../../service-provider";
import {
    DatasetConvertService,
    DatasetFormatsService
} from "../../../services/space/datasets/interface";
import { ApiResponse, SpaceServiceKey } from "../../../services";
import { ProcessFile } from "../../../utilities/dataset-page/processFileUtil";
import {
    HeaderRendererFull,
    RowRendererFull,
    ColumnsRenderer,
    EmptyGrid
} from "./data-grid";
import {
    DatasetsModal,
    RemoveDatasetsModal,
    DragAndDropUpload
} from "../../../components";
import { useAuth } from "../../../hooks/use-auth";
import { localStorageService } from "../../../utilities/dataset-page/localStorageService";
import {
    DatasetFormatsResponse,
    StoredDataset
} from "@services/space/datasets/models";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faTrash } from "@fortawesome/pro-light-svg-icons";
import {
    CyRheologyParameters,
    defaultCyRheologyParameters,
    ViscosityParameters,
    defaultViscosityParameters,
    RheologyComparisonParameters,
    RheologyOverlayParameters,
    defaultRheologyOverlayParameters
} from "../../../interfaces/analysis-types";
import {
    cyRheologyAnalysisType,
    viscosityReportType,
    rheologyComparisonReportType,
    rheologyOverlayReportType
} from "../../../utilities/constants/cy-rheology";
import { useNavigate } from "react-router-dom";
import { spaceTrackEvent } from "../../../logging/space-track-event";
import {
    DELETE_DATASET,
    ERROR_BAD_DATA_FILE_UPLOAD,
    ERROR_DUPLICATE_FILE_UPLOAD,
    ERROR_GET_DATASET_FORMATS,
    ERROR_INVALID_FILE_FORMAT_UPLOAD,
    ERROR_LOADING_USER_DATASETS,
    GET_DATASET_FORMATS,
    LOADING_DATASETS,
    NAVIGATE_TO_ANALYSIS_PAGE,
    OPEN_DATASET
} from "../../../logging/log-events";
import { FeatureFlagService } from "@services/feature-flags/interface";
import { FeatureFlagServiceKey } from "@services/feature-flags";

export interface DatasetsPageViewProps {
    className?: string;
    testId?: string;
}

export function DatasetsPageView({
    className,
    testId
}: DatasetsPageViewProps): JSX.Element {
    const cnParts = ["space-datasets-page"];
    const gridWrapperCnParts = ["data-grid-wrapper"];
    const navigate = useNavigate();
    const [datasets, setDatasets] = useState<StoredDataset[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
    const [isDragOver, setIsDragOver] = useState(false);
    const { state, dispatch } = useDatasetManagement();
    const { createToast, dismissAllToasts } = useToast();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const datasetFormatsService =
        useService<DatasetFormatsService>(SpaceServiceKey);
    const datasetConvertService =
        useService<DatasetConvertService>(SpaceServiceKey);
    const { currentAccount } = useAuth();
    const featureFlagService = useService<FeatureFlagService>(
        FeatureFlagServiceKey
    );

    const featureFlags = featureFlagService.getFlagsHook()();

    const currentUserId = currentAccount?.username;

    let dragTimeOut: number;

    const [isDatasetsModalOpen, setIsDatasetsModalOpen] = useState(false);
    const [isRemoveDatasetsModalOpen, setIsRemoveDatasetsModalOpen] =
        useState(false);

    const [selectedDataset, setSelectedDataset] =
        useState<StoredDataset | null>(null);

    async function FetchDatasetFormats() {
        try {
            if (getDatasetFormats(state).length === 0) {
                const response =
                    await datasetFormatsService.getAllDatasetFormats();
                if (response?.data) {
                    dispatch(
                        setDataFormats(
                            (response as ApiResponse<DatasetFormatsResponse>)
                                .data.datasetFormats
                        )
                    );
                    spaceTrackEvent({
                        type: GET_DATASET_FORMATS,
                        contents: JSON.stringify({
                            datasetFormats: (
                                response as ApiResponse<DatasetFormatsResponse>
                            ).data.datasetFormats
                        })
                    });
                }
            }
        } catch (error) {
            const errorMessage =
                error instanceof Error
                    ? error.message
                    : "Failed to fetch dataset formats";
            dispatch(postConvertDatasetFailure({ title: errorMessage }));
            spaceTrackEvent({
                type: ERROR_GET_DATASET_FORMATS,
                contents: errorMessage
            });
        }
    }

    async function FetchLocalUserDatasets() {
        try {
            if (currentUserId) {
                const userDatasets =
                    localStorageService.loadDatasetsForUser(currentUserId);
                if (userDatasets) {
                    setDatasets(userDatasets);
                    spaceTrackEvent({
                        type: LOADING_DATASETS,
                        contents: JSON.stringify(userDatasets)
                    });
                }
            }
        } catch (error) {
            const errorMessage =
                error instanceof Error
                    ? error.message
                    : "Failed to get local user datasets";
            dispatch(
                postFetchLocalUserDatasetsFailure({ title: errorMessage })
            );
            spaceTrackEvent({
                type: ERROR_LOADING_USER_DATASETS,
                contents: errorMessage
            });
        }
    }

    useEffect(() => {
        FetchDatasetFormats();
        FetchLocalUserDatasets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dataGridEmpty = datasets.length === 0 ? "empty-data-grid" : undefined;

    if (className) {
        cnParts.push(className);
    }
    if (dataGridEmpty) {
        gridWrapperCnParts.push(dataGridEmpty);
    }

    const dataGridTestId = testId ? `${testId}-datagrid` : undefined;
    const dataGridRowsTestId = testId ? `${testId}-datagrid-row` : undefined;

    function HandleUploadClick() {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    function handleDragEnter(event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault();
        clearTimeout(dragTimeOut);
        setIsDragOver(true);
    }

    function handleDragOver(event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();
        if (!isDragOver) {
            setIsDragOver(true);
        }
    }

    function handleDragLeave(event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault();

        if (!event.currentTarget.contains(event.relatedTarget as Node)) {
            dragTimeOut = window.setTimeout(() => {
                setIsDragOver(false);
            }, 100);
        }
    }

    function handleDrop(event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();
        clearTimeout(dragTimeOut);
        setIsDragOver(false);
    }

    async function HandleFileUpload(files: File[]) {
        const validExtensions = new Set([".rad", ".txt"]);

        const isValidFile = (file: File) => {
            const fileExtension = file.name
                .slice(file.name.lastIndexOf("."))
                .toLowerCase();
            return validExtensions.has(fileExtension);
        };

        const invalidFiles = files.filter((file) => !isValidFile(file));
        const invalidFilesNames = invalidFiles.map((file) => file.name);

        if (invalidFiles.length > 0) {
            invalidFiles.forEach((file) => {
                createToast(
                    `${file.name}: Invalid file format. Refer to user manual for accepted file formats`,
                    {
                        id: `${file.name}-invalid-format-warning`,
                        severity: "danger",
                        autoDismissDelay: 10000,
                        title: "Error"
                    }
                );
            });
            spaceTrackEvent({
                type: ERROR_INVALID_FILE_FORMAT_UPLOAD,
                contents: JSON.stringify({ invalidFilesNames, currentUserId })
            });
            return; // Stop further processing if there are invalid files, will not stop valid files in the same group upload
        }

        const existingFileNames = new Set(
            datasets.map((dataset) => dataset.fileName)
        );

        const oldFiles = files.filter((file) =>
            existingFileNames.has(file.name)
        );
        const oldFilesNames = oldFiles.map((file) => file.name);

        if (oldFiles.length > 0) {
            dismissAllToasts();
            oldFiles.forEach((file) => {
                createToast(`${file.name}: file already exists`, {
                    id: `${file.name}-duplicate-warning`,
                    severity: "caution",
                    autoDismissDelay: 10000,
                    title: "Warning"
                });
            });
            spaceTrackEvent({
                type: ERROR_DUPLICATE_FILE_UPLOAD,
                contents: JSON.stringify(oldFilesNames)
            });
        }

        const newFiles = files.filter(
            (file) => !existingFileNames.has(file.name)
        );

        if (newFiles.length === 0) return;

        const fileProcessingPromises = newFiles.map((file) =>
            ProcessFile(file, dispatch, datasetConvertService)
        );

        const processedFiles: (StoredDataset | null)[] = await Promise.all(
            fileProcessingPromises
        );

        const successfulFiles = processedFiles.filter(
            (item): item is StoredDataset => item !== null
        );

        const failedFiles = newFiles.filter(
            (file, index) => processedFiles[index] === null
        );

        const failedFilesNames = failedFiles.map((file) => file.name);

        if (failedFiles.length > 0) {
            failedFiles.forEach((file) => {
                createToast(`${file.name}: Verify data content is compatible`, {
                    id: `${file.name}-incompatible-data-warning`,
                    severity: "danger",
                    autoDismissDelay: 10000,
                    title: "Error"
                });
            });
            spaceTrackEvent({
                type: ERROR_BAD_DATA_FILE_UPLOAD,
                contents: JSON.stringify({ failedFilesNames, currentUserId })
            });
        }

        const newDatasets = [...datasets, ...successfulFiles];
        setDatasets(newDatasets);

        if (currentUserId) {
            localStorageService.saveDatasetsForUser(currentUserId, newDatasets);
        }
    }

    function handleFileUploadButton(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const files = event.target.files ? Array.from(event.target.files) : [];
        if (files.length > 0) {
            HandleFileUpload(files);
            event.target.value = "";
        }
    }

    function HandleSelectFile(fileName: string, isSelected: boolean) {
        setSelectedFiles((prevSelectedFiles) => {
            if (isSelected) {
                return [...prevSelectedFiles, fileName];
            }

            return prevSelectedFiles.filter((file) => file !== fileName);
        });
    }

    const allFilesSelected =
        selectedFiles.length === datasets.length && selectedFiles.length !== 0;

    function HandleSelectAllFiles() {
        if (allFilesSelected) {
            setSelectedFiles([]);
        } else {
            setSelectedFiles(datasets.map((file) => file.fileName));
        }
    }

    function HandleRemoveFile(fileName: string) {
        const updatedDatasets = datasets.filter(
            (dataset) => dataset.fileName !== fileName
        );
        setDatasets(updatedDatasets);
        setSelectedFiles((prevSelectedFiles) =>
            prevSelectedFiles.filter((file) => file !== fileName)
        );

        if (currentUserId) {
            localStorageService.saveDatasetsForUser(
                currentUserId,
                updatedDatasets
            );
        }
        spaceTrackEvent({
            type: DELETE_DATASET,
            contents: fileName
        });
    }

    function HandleDeselectAll() {
        setSelectedFiles([]);
    }

    function HandleDatasetFileNameClick(fileName: string) {
        const dataset = datasets.filter(
            (dataset) => dataset.fileName === fileName
        );
        setSelectedDataset(dataset[0]);
        setIsDatasetsModalOpen(!isDatasetsModalOpen);
        spaceTrackEvent({
            type: OPEN_DATASET,
            contents: JSON.stringify({ dataset })
        });
    }

    function HandleRemoveDatasetsButtonClick(
        datasetsToDelete: StoredDataset[]
    ) {
        const updatedDatasets = datasets.filter(
            (dataset) =>
                !datasetsToDelete.some(
                    (datasetToDelete) =>
                        datasetToDelete.fileName === dataset.fileName
                )
        );
        setIsRemoveDatasetsModalOpen(!isRemoveDatasetsModalOpen);

        setDatasets(updatedDatasets);
        setSelectedFiles((prevSelectedFiles) =>
            prevSelectedFiles.filter(
                (file) =>
                    !datasetsToDelete.some(
                        (datasetToDelete) => datasetToDelete.fileName === file
                    )
            )
        );

        const selectedDatasetsNamesToDelete = datasetsToDelete.map(
            (dataset) => dataset.fileName
        );

        if (currentUserId) {
            localStorageService.removeDatasetsForUser(
                currentUserId,
                selectedDatasetsNamesToDelete
            );
            localStorageService.saveDatasetsForUser(
                currentUserId,
                updatedDatasets
            );
        }
        setIsRemoveDatasetsModalOpen(false);
    }

    function HandleReportAnalysisClick(selectedAnalysisType: string) {
        state.analysisResponse = null;
        state.error = null;
        const datasetsForAnalysis = datasets.filter((dataset) =>
            selectedFiles.includes(dataset.fileName)
        );

        if (datasetsForAnalysis.length > 0) {
            const cyRheologyParams: CyRheologyParameters = {
                ...defaultCyRheologyParameters,
                selectedDatasets: datasetsForAnalysis
            };
            const viscosityParams: ViscosityParameters = {
                ...defaultViscosityParameters,
                selectedDatasetGroups: [
                    {
                        name: "Group 1",
                        group: datasetsForAnalysis
                    }
                ]
            };
            const rheologyComparisonParams: RheologyComparisonParameters = {
                ...defaultCyRheologyParameters,
                selectedDatasets: datasetsForAnalysis
            };
            const rheologyOverlayParams: RheologyOverlayParameters = {
                ...defaultRheologyOverlayParameters,
                selectedDatasets: datasetsForAnalysis
            };

            switch (selectedAnalysisType) {
                case "cyRheology":
                    localStorageService.saveAnalysisParametersForUser(
                        currentUserId as string,
                        { analysisType: selectedAnalysisType, cyRheologyParams }
                    );
                    spaceTrackEvent({
                        type: NAVIGATE_TO_ANALYSIS_PAGE,
                        contents: JSON.stringify({
                            selectedAnalysisType,
                            datasetsForAnalysis
                        })
                    });
                    navigate("/report-preview");
                    break;
                case "viscosity":
                    localStorageService.saveAnalysisParametersForUser(
                        currentUserId as string,
                        { analysisType: selectedAnalysisType, viscosityParams }
                    );
                    spaceTrackEvent({
                        type: NAVIGATE_TO_ANALYSIS_PAGE,
                        contents: JSON.stringify({
                            selectedAnalysisType,
                            datasetsForAnalysis
                        })
                    });
                    navigate("/report-preview");
                    break;
                case "rheologyComparison":
                    localStorageService.saveAnalysisParametersForUser(
                        currentUserId as string,
                        {
                            analysisType: selectedAnalysisType,
                            rheologyComparisonParams
                        }
                    );
                    spaceTrackEvent({
                        type: NAVIGATE_TO_ANALYSIS_PAGE,
                        contents: JSON.stringify({
                            selectedAnalysisType,
                            datasetsForAnalysis
                        })
                    });
                    navigate("/report-preview");
                    break;
                case "rheologyOverlay":
                    localStorageService.saveAnalysisParametersForUser(
                        currentUserId as string,
                        {
                            analysisType: selectedAnalysisType,
                            rheologyOverlayParams
                        }
                    );
                    spaceTrackEvent({
                        type: NAVIGATE_TO_ANALYSIS_PAGE,
                        contents: JSON.stringify({
                            selectedAnalysisType,
                            datasetsForAnalysis
                        })
                    });
                    navigate("/plots");
                    break;
                default:
                    console.error(
                        "Invalid analysis type selected:",
                        selectedAnalysisType
                    );
                    break;
            }
        } else {
            console.error("No datasets selected for analysis.");
        }
    }

    const gridProps = {
        data: datasets,
        columns: ColumnsRenderer(),
        useStickyHeaders: true,
        headerRenderer: () => (
            <HeaderRendererFull
                allFilesSelected={allFilesSelected}
                onSelectAllFiles={HandleSelectAllFiles}
                isDatasetsPage
            />
        ),

        /**
         *
         * @param row - To pass new data to row, please adjust ColumnsRenderer with the appropriate accessor and id values
         * @returns
         */

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rowRenderer: (row: any) =>
            RowRendererFull(
                row,
                HandleSelectFile,
                selectedFiles,
                true,
                HandleRemoveFile,
                HandleDatasetFileNameClick,
                dataGridRowsTestId
            ),
        testId: dataGridTestId
    };

    const GenerateAnalysisMenuItems = [
        {
            key: "CY-Rheology-Analysis",
            content: "CY Rheology Analysis",
            onClick: () => HandleReportAnalysisClick(cyRheologyAnalysisType)
        }
    ];

    if (featureFlags.enableViscosityReport === true) {
        GenerateAnalysisMenuItems.push({
            key: "Viscosity-Report",
            content: "Viscosity Report",
            onClick: () => HandleReportAnalysisClick(viscosityReportType)
        });
    }

    if (featureFlags.enableRheologyComparisonReport === true) {
        GenerateAnalysisMenuItems.push({
            key: "Rheology-Comparison",
            content: "Rheology Comparison",
            onClick: () =>
                HandleReportAnalysisClick(rheologyComparisonReportType)
        });
    }

    const selectedButtonText = `${selectedFiles.length} Selected`;

    const isGenerateAnalysisDisabled = selectedFiles.length === 0;

    const generateAnalysisCn = isGenerateAnalysisDisabled
        ? "datasets-generate-analysis-button analysis-disabled"
        : "datasets-generate-analysis-button";

    const generateAnalysisMenuCn = isGenerateAnalysisDisabled
        ? "datasets-generate-analysis-menu menu-disabled"
        : "datasets-generate-analysis-menu";

    const plotsFeatureFlagEnabled = featureFlags.enablePlots === true;
    const gridWrapperCn = gridWrapperCnParts.join(" ");
    const cn = cnParts.join(" ");
    return (
        <div className={cn} data-testid={testId}>
            <h1 className="datasets-heading" data-testid="datasets-page-title">
                Datasets
            </h1>
            <div className="upload-wrapper">
                <Card className="datasets-card">
                    {isDatasetsModalOpen && selectedDataset && (
                        <DatasetsModal
                            dataset={selectedDataset}
                            isOpen={isDatasetsModalOpen}
                            onRequestClose={() =>
                                HandleDatasetFileNameClick(
                                    selectedDataset.fileName
                                )
                            }
                            onRemoveDataset={() =>
                                HandleRemoveFile(selectedDataset.fileName)
                            }
                        />
                    )}
                    {isRemoveDatasetsModalOpen && (
                        <RemoveDatasetsModal
                            className="delete-datasets-modal"
                            isOpen={isRemoveDatasetsModalOpen}
                            selectedDatasets={selectedFiles.length}
                            onRemoveDatasets={() =>
                                HandleRemoveDatasetsButtonClick(
                                    datasets.filter((dataset) =>
                                        selectedFiles.includes(dataset.fileName)
                                    )
                                )
                            }
                            onRequestClose={() =>
                                setIsRemoveDatasetsModalOpen(false)
                            }
                        />
                    )}
                    <div className="datasets-datagrid-buttons">
                        <Button
                            className="datasets-upload-button"
                            color="primary"
                            text="Upload"
                            isIconAfterText
                            testId="datasets-upload-button"
                            icon={
                                <FontAwesomeIcon
                                    icon={faCloudArrowUp as IconProp}
                                />
                            }
                            onClick={HandleUploadClick}
                        />
                        <div className="datasets-selector-items">
                            {selectedFiles.length > 1 && (
                                <>
                                    <Button
                                        className="datasets-clear-selected-button"
                                        color="primary"
                                        text={selectedButtonText}
                                        variant="text"
                                        icon={
                                            <FontAwesomeIcon
                                                icon={faX as IconProp}
                                            />
                                        }
                                        onClick={HandleDeselectAll}
                                    />
                                    <Button
                                        className="delete-datasets-button"
                                        color="primary"
                                        text="Remove"
                                        variant="text"
                                        icon={
                                            <FontAwesomeIcon
                                                icon={faTrash as IconProp}
                                            />
                                        }
                                        onClick={() =>
                                            setIsRemoveDatasetsModalOpen(true)
                                        }
                                    />
                                </>
                            )}
                            {plotsFeatureFlagEnabled && (
                                <Button
                                    className={generateAnalysisCn}
                                    color="primary"
                                    variant="text"
                                    text={"Generate Plots"}
                                    isDisabled={selectedFiles.length === 0}
                                    testId="generate-plots-button"
                                    onClick={() =>
                                        HandleReportAnalysisClick(
                                            rheologyOverlayReportType
                                        )
                                    }
                                />
                            )}

                            <Menu
                                baseElement={
                                    <Button
                                        className={generateAnalysisCn}
                                        color="primary"
                                        variant="text"
                                        text={"Generate Analysis"}
                                        isDisabled={selectedFiles.length === 0}
                                        isIconAfterText
                                        testId="generate-analysis-button"
                                        icon={
                                            <FontAwesomeIcon
                                                icon={faChevronDown as IconProp}
                                            />
                                        }
                                    />
                                }
                                menuItems={GenerateAnalysisMenuItems}
                                className={generateAnalysisMenuCn}
                                disableActiveStyles={selectedFiles.length === 0}
                            />
                        </div>
                    </div>

                    <input
                        className="upload-input"
                        type="file"
                        ref={fileInputRef}
                        data-testid="dataset-file-input"
                        onChange={handleFileUploadButton}
                        multiple
                        accept={state.datasetFormats
                            .map((format) => format.extension || "")
                            .join(",")}
                    />
                    <div
                        className={gridWrapperCn}
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                    >
                        <DataGrid {...gridProps} />
                        <DragAndDropUpload
                            onFilesAdded={HandleFileUpload}
                            isDragActive={isDragOver}
                        />
                    </div>

                    {datasets.length === 0 && <EmptyGrid />}
                </Card>
            </div>
        </div>
    );
}
